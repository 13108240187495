/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from 'react';
import ReactDOM from 'react-dom';
import SubmissionsCollectionExpiration from 'components/submissionsCollectionExpiration';
import HtmlToText from 'html-to-text';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import Swiper from 'swiper/bundle';
import "@hotwired/turbo-rails";
import 'controllers';

Turbo.session.drive = false

document.addEventListener('turbo:load', () => {
   let nodes = document.getElementsByClassName('js-submissions-collection-expiration');
   nodes = [...nodes]
   nodes.forEach(node => {
      const data = JSON.parse(node.getAttribute('data'));
      ReactDOM.render(<SubmissionsCollectionExpiration {...data} />, node);
   })
});

window.uuidv4 = uuidv4;
window.HtmlToText = HtmlToText;
window.ReactDOM = ReactDOM;
window.React = React;
window._ = _;
window.Swiper = Swiper;
