import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="kronickle--annotations--index"
export default class extends Controller {
  static targets = ["annotation", "trigger"];

  selectAnnotationMarkup({ params, target }) {
    const selectedAnnotationId = params.annotationId;

    if (this.#isTrigger(target)) {
      this.dispatch("annotation-selected", {
        detail: { annotationId: selectedAnnotationId },
      });
    }

    this.annotationTargets.forEach((el) => {
      const annotationId =
        el.dataset["kronickle-Annotations-IndexAnnotationIdParam"];
      if (annotationId === selectedAnnotationId) {
        el.dispatchEvent(
          new CustomEvent("kronickle--annotations--index:expand-comments", {
            bubbles: true,
          }),
        );
        el.scrollIntoView({ behavior: "smooth" });
      } else {
        el.dispatchEvent(
          new CustomEvent("kronickle--annotations--index:collapse-comments", {
            bubbles: true,
          }),
        );
      }
    });
  }

  selectAnnotationComments({ detail }) {
    this.annotationTargets.forEach((el) => {
      const annotationId =
        el.dataset["kronickle-Annotations-IndexAnnotationIdParam"];

      if (annotationId === detail.annotationId) {
        el.dispatchEvent(
          new CustomEvent("kronickle--annotations--index:expand-comments", {
            bubbles: true,
          }),
        );
        el.scrollIntoView({ behavior: "smooth" });
      } else {
        el.dispatchEvent(
          new CustomEvent("kronickle--annotations--index:collapse-comments", {
            bubbles: true,
          }),
        );
      }
    });
  }

  #isTrigger(target) {
    return this.triggerTargets.indexOf(target) >= 0;
  }
}
