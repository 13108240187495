import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dept-amg--programmer-reviews--submission-feedback"
export default class extends Controller {
  static values = {
    url: String,
  }

  initialize() {
    this.formData = new FormData();
  };

  decline() {
    this.formData.append('submission[status]', 'declined');
    submitFeedback(this.urlValue, this.formData);
  }

  approve() {
    this.formData.append('submission[status]', 'approved');
    submitFeedback(this.urlValue, this.formData);
  }

}

const submitFeedback = (url, formData) => {
  const token = document.querySelector('meta[name="csrf-token"]').content;

  fetch(url, {
    method: 'PATCH',
    body: formData,
    headers: {
      'X-CSRF-Token': token,
    },
    credentials: 'same-origin',
  }).then(response => {
    location.href = location.href;
  });
}
