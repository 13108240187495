import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--date-range-presets"
export default class extends Controller {
  static targets = ["selector", "startDate", "endDate"];

  connect() {}

  updateDates(event) {
    const selectElement = event.currentTarget;
    if (selectElement.value === "custom") {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.startDateTarget.hidden = false;
      this.endDateTarget.hidden = false;

      this.startDateTarget.focus();
      return;
    } else {
      this.startDateTarget.blur();
      this.endDateTarget.blur();

      this.startDateTarget.hidden = true;
      this.endDateTarget.hidden = true;
    }

    const selectedIndex = selectElement.selectedIndex;
    const selectedOption = selectElement.options[selectedIndex];
    const startDate = selectedOption.getAttribute('data-start-date');
    const endDate = selectedOption.getAttribute('data-end-date');
    this.startDateTarget.value = startDate;
    this.endDateTarget.value = endDate;
  }

  updateSelector() {
    const startDate = this.startDateTarget.value;
    const endDate = this.endDateTarget.value;
    const selectElement = this.selectorTarget;
    const options = selectElement.options;

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionStartDate = option.getAttribute('data-start-date');
      const optionEndDate = option.getAttribute('data-end-date');

      if (optionStartDate === startDate && optionEndDate === endDate) {
        selectElement.selectedIndex = i;
        return;
      }
    }

    selectElement.selectedIndex = 0;
  }
}
