import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--select-toggle-visibility"
export default class extends Controller {
  static targets = ["element", "select"]

  connect() {
    this.toggleElements()
  }

  toggleElements() {
    for (let element of this.elementTargets) {
      this.toggle(element, element.dataset.showIf, this.selectTarget.value);
    }
  }

  toggle(element, showIfValues, selectedValue) {
    if (selectedValue) {
      if (showIfValues.split(",").includes(selectedValue)) {
        element.hidden = false;
      } else {
        element.hidden = true;
      }
    } else {
      element.hidden = true;
    }
  }
}
