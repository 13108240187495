import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--chartkick-click"
export default class extends Controller {
  static values = {
    id: String,
    urls: String,
  }

  connect() {
    let chart = Chartkick.charts[this.idValue]
    let chartConfig = chart.getChartObject().config
    let chartObject = chart.getChartObject()

    chartConfig.options.onHover = (event) => {
      const points = chartObject.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
      let canvas = chartObject.canvas

      if (points.length) {
        canvas.style.cursor = 'pointer'
      } else {
        canvas.style.cursor = 'default'
      }
    }

    chartConfig.options.onClick = (event) => {
      const points = chartObject.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

      if (points.length) {
        const firstPoint = points[0];
        const label = chartObject.data.labels[firstPoint.index];
        const url = JSON.parse(this.urlsValue)[label]
        window.location = url
      }
    }
  }
}
