const generateButton = ({ label, classNames }) => {
  const buttonEl = document.createElement("button");
  buttonEl.classList.add("MarkupToolbar__button");
  buttonEl.classList.add(classNames);
  buttonEl.textContent = label;

  return buttonEl;
};

const saveModal = {
  dataElement: "saving-markups",
  header: {
    title: "Saving markups. Please wait...",
  },
};

const loadAnnotations = (url) => {
  return new Promise((resolve) => {
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((annotations) => resolve(annotations.map((a) => a.xfdf)));
  });
};

const sendMarkupSession = (xfdfString, url, imageBlobs) => {
  const token = document.querySelector('meta[name="csrf-token"]').content;

  const formData = new FormData();

  const jsonParams = JSON.stringify({
    xfdf: xfdfString,
  });

  formData.append("markup_session", jsonParams);

  if (imageBlobs) {
    Object.entries(imageBlobs).forEach(([uuid, blob]) => {
      formData.append(`thumbnails[${uuid}]`, blob);
    });
  }

  return fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      "X-CSRF-Token": token,
    },
    credentials: "same-origin",
  });
};

const getImageBlobs = async (xfdfString, documentViewer, annotationManager) => {
  const imageBlobs = {};

  const commandData = new DOMParser().parseFromString(xfdfString, "text/xml");
  const addedAnnots = Array.from(commandData.getElementsByTagName("add")[0].childNodes);
  const modifiedAnnots = Array.from(commandData.getElementsByTagName("modify")[0].childNodes);

  const annotations = addedAnnots
    .concat(modifiedAnnots)
    .filter((node) => node.nodeType !== node.TEXT_NODE)
    .filter((node) => !node.getAttribute("inreplyto"))
    .map((node) =>
      annotationManager.getAnnotationById(node.getAttribute("name")),
    );

  annotationManager.hideAnnotations(annotationManager.getAnnotationsList());

  const doc = documentViewer.getDocument();

  // Load canvases and create image blobs for each annotation
  await Promise.all(annotations.map(async (annotation) => {
    const pageNumber = Number.parseInt(annotation.PageNumber);
    const uuid = annotation.Id;
    const zoom = 2;

    await new Promise((resolve) => {
      doc.loadCanvas({
        pageNumber: pageNumber,
        zoom: zoom,
        drawComplete: async (canvas) => {
          const internalPageRotation = (doc.getPageRotation(pageNumber) / 90) % 4; // 1,2,3 based on rotation
          annotationManager.setAnnotationCanvasTransform(canvas.getContext("2d"), zoom, internalPageRotation);

          annotationManager.showAnnotation(annotation);

          await annotationManager.drawAnnotations({
              pageNumber: pageNumber,
              overrideCanvas: canvas,
            })

          annotationManager.hideAnnotation(annotation);

          canvas.toBlob((blob) => {
            imageBlobs[uuid] = blob;
            resolve();
          });
        },
      });
    });
  }));

  annotationManager.showAnnotations(annotationManager.getAnnotationsList());

  return imageBlobs;
};

export {
  generateButton,
  getImageBlobs,
  loadAnnotations,
  saveModal,
  sendMarkupSession,
};
