import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--search-form"
export default class extends Controller {
  static targets = ["input", "resetButton"];
  static values = { defaultState: Object };

  connect() {
    this.defaultState = this.defaultStateValue || this.getCurrentState();
    this.updateResetButtonVisibility();
    this.boundUpdateResetButtonVisibility = this.updateResetButtonVisibility.bind(this);
    this.element.addEventListener("input", this.boundUpdateResetButtonVisibility);
    this.element.addEventListener("change", this.boundUpdateResetButtonVisibility);
  }

  disconnect() {
    this.element.removeEventListener("input", this.boundUpdateResetButtonVisibility);
    this.element.removeEventListener("change", this.boundUpdateResetButtonVisibility);
  }

  getCurrentState() {
    return Array.from(this.element.elements).reduce((state, input) => {
      if (input.dataset.ignore !== "true") {
        if (input.type === "checkbox") {
          state[input.name] = input.checked;
        } else {
          state[input.name] = $(input).val();
        }
      }

      return state;
    }, {});
  }

  updateResetButtonVisibility() {
    const currentState = this.getCurrentState();
    const isDifferent = Object.keys(this.defaultState).some(key => {
      return !valuesAreEqual(this.defaultState[key], currentState[key]);
    });

    this.resetButtonTarget.style.display = isDifferent ? "block" : "none";
  }

  reset() {
    Array.from(this.element.elements).forEach(element => {
      if (element.dataset.ignore !== "true" && this.defaultState.hasOwnProperty(element.name)) {
        if (element.type === "checkbox") {
          element.checked = this.defaultState[element.name];
        } else {
          element.value = this.defaultState[element.name];
          if (element.classList.contains('jqmsLoaded')) {
            $(element).multiselect('reload')
          }
        }
      }
    });
    this.updateResetButtonVisibility();
    this.element.requestSubmit();
  }
}

function valuesAreEqual(value1, value2) {
  value1 = value1 ?? '';
  value2 = value2 ?? '';

  if (Array.isArray(value1) && Array.isArray(value2)) {
    return _.isEqual(value1, value2);
  }

  return value1 === value2;
}
