import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--add-remove-elements"
export default class extends Controller {
  static targets = ["template", "container"];

  connect() {
    this.templateTarget.hidden = true;
  }

  addElement(event) {
    event.preventDefault();

    for (const child of this.templateTarget.children) {
      const clone = child.cloneNode(true);
      this.containerTarget.appendChild(clone);
    }
  }

  removeElement(event) {
    event.preventDefault();

    event.target.closest(event.params.elementIdentifier).remove();
  }
}
