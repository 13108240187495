import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--date-picker"
export default class extends Controller {
  static targets = ["maxDate", "minDate"];
  static values = {
    format: String,
    minDate: String,
  };

  connect() {
    const options = {};
    options.format = this.formatValue || "L";
    options.minDate = this.minDateValue && new Date(this.minDateValue);
    options.onSelect = () => {
      this.dispatch("select");
    };
    options.field = this.element;

    this.picker = new Pikaday(options);
    this.element.readOnly = true;
  }

  disconnect() {
    this.picker.destroy();
  }
}
