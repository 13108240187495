import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import { debounce } from "lodash";

// Connects to data-controller="shared--tabulator"
export default class extends Controller {
  static values = {
    ajaxSorting: { type: Boolean, default: false },
    ajaxUrl: String,
    columns: { type: Array, default: [] },
    data: Array,
    dateFormat: { type: String, default: "mm/dd/yyyy" },
    filters: { type: Array, default: [] },
    initialSort: Array,
    layout: { type: String, default: "fitColumns" },
    pagination: { type: String, default: "local" },
    paginationSize: { type: Number, default: 20 },
    placeholder: String,
    url: String,
  };

  static targets = ["table", "count", "filterInput"];

  connect() {}

  tableTargetDisconnected(_target) {
    this.$table.tabulator("destroy");
  }

  tableTargetConnected(_target) {
    const {
      ajaxSortingValue,
      ajaxURLGenerator,
      ajaxUrlValue,
      columnsValue,
      dataValue,
      dateFormatValue,
      initialSortValue,
      layoutValue,
      paginationSizeValue,
      paginationValue,
      placeholderValue,
      tableTarget,
    } = this;

    this.$table = $(tableTarget).tabulator({
      ajaxResponse: ajaxUrlValue ? this.ajaxResponseCallback.bind(this) : null,
      ajaxSorting: ajaxSortingValue,
      ajaxURLGenerator: ajaxUrlValue ? ajaxURLGenerator.bind(this) : null,
      columns: columnsValue,
      dataFiltered: this.#handleDataFiltered.bind(this),
      dateFormat: dateFormatValue,
      initialSort: initialSortValue,
      layout: layoutValue,
      pagination: paginationValue,
      paginationSize: paginationSizeValue,
      placeholder: placeholderValue,
      rowFormatter,
    });

    if (dataValue.length) {
      this.$table.tabulator("setData", dataValue);
    }
    this.loadTableData();
    this.filter = debounce(this.filter, 300);
    $(".TableView__data").show();
    this.$table.tabulator("redraw");
  }

  refresh() {
    this.loadTableData();
  }

  addRow(event) {
    const { data } = event.detail;
    const rows = this.$table.tabulator("getData");
    const existingRow = rows.find(
      (row) =>
        row.id.toString().toLowerCase() === data.id.toString().toLowerCase()
    );
    if (existingRow) {
      this.updateRow(event);
    } else {
      this.$table.tabulator("addRow", data, true);
    }
  }

  updateRow(event) {
    const { data } = event.detail;
    data.id = parseInt(data.id, 10);
    this.$table.tabulator("updateData", [data]);
  }

  filter(event) {
    const { filtersValue } = this;
    const value = event.target.value;
    const filters = filtersValue.map((filter) => ({
      field: filter.field,
      type: filter.type,
      value: value,
    }));
    this.$table.tabulator("setFilter", [filters]);
  }

  ajaxURLGenerator(url, config, params) {
    const { ajaxUrlValue } = this;
    const sorter = params.sorters[0] || {};
    const sortBy = sorter.field;
    const sortDir = sorter.dir;

    const queryParams = {
      "filter[page]": params.page,
      "filter[pagination_size]": params.size,
      "filter[sort_by]": sortBy,
      "filter[sort_dir]": sortDir,
    };

    const searchParams = new URLSearchParams(queryParams);
    if (this.hasFilterInputTarget) {
      this.filterInputTargets.forEach((input) => {
        const value = $(input).val();
        if (Array.isArray(value)) {
          value.forEach((v) => searchParams.append(input.name, v));
        } else {
          searchParams.append(input.name, value);
        }
      });
    }

    return `${ajaxUrlValue}?${searchParams}`;
  }

  ajaxResponseCallback(url, params, response) {
    if (this.countTarget && Number.isInteger(response.total_count)) {
      this.countTarget.textContent = response.total_count;
    }

    return response
  }

  async loadTableData() {
    const { urlValue } = this;

    if (!urlValue) return;

    const response = await get(urlValue, { responseKind: "json" });
    const rows = await response.json;

    this.$table.tabulator("setData", rows).then(() => {
      $(".TableView__loading").hide();
      $(".TableView__data").show();
      this.$table.tabulator("redraw");
    });
  }

  #handleDataFiltered(_filters, rows) {
    this.dispatch("filtered", { detail: { rows } });
  }
}

const rowFormatter = (row) => {
  const data = row.getData();

  if (data.cssClass) {
    const classList = row.getElement().classList;
    classList.forEach((className) => {
      if (className.indexOf('tabulator') === -1) {
        classList.remove(className);
      }
    });

    classList.add(data.cssClass);
  }
};
