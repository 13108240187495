import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--params-sync"
export default class extends Controller {
  connect() {
    this.updateLink();
    document.addEventListener("turbo:load", () => this.updateLink());
    document.addEventListener("turbo:frame-load", () => this.updateLink());
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.updateLink);
    document.removeEventListener("turbo:frame-load", this.updateLink);
  }

  updateLink = () => {
    const linkElement = this.element;
    const currentURL = new URL(window.location);
    const originalURL = new URL(linkElement.href);

    currentURL.searchParams.forEach((value, key) => {
      originalURL.searchParams.set(key, value);
    });

    linkElement.href = originalURL.toString();
  }
}
