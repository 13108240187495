import { Controller } from "@hotwired/stimulus"
import Clipboard from 'clipboard'

// Connects to data-controller="shared--copy-to-clipboard"
export default class extends Controller {
  connect() {
    const $copyButton = $(this.element)
    new Clipboard($copyButton[0])

    $copyButton.on('click', function(e) {
      const $span = $(this).find('span')
      const originalContent = $span.html();
      $span.html('Copied!')
      setTimeout(() => $span.html(originalContent), 3000)
    })
  }
}
