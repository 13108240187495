import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--ui-preferences"
export default class extends Controller {
  connect() {}

  /**
   * event.params.ui: string contains the name of the UI preference
   * event.params.value: string (optional)
   *  contains the value of the UI preference. Otherwise is considered a checkbox
   *  and the value is taken from event.target.checked
   */
  update(event) {
    if (event.params.value !== undefined) {
      document.cookie = `${event.params.ui}=${event.params.value}; path=/`;
    } else {
      document.cookie = `${event.params.ui}=${event.target.checked}; path=/`;
    }
  }
}
