import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--copy-input-value"
export default class extends Controller {
  static targets = ["source", "destination"];

  connect() {
    this.sourceTarget.addEventListener("change", this.copyValue);
  }

  copyValue = () => {
    this.destinationTargets.forEach((destination) => {
      destination.value = this.sourceTarget.value;
    });
  };
}
