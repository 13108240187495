import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shared--tabs"
export default class extends Controller {
  static classes = [ "selectedTab", "selectedContent" ]
  static targets = [ "tab" , "content" ]

  connect() {
  }

  select(event) {
    let index = this.tabTargets.indexOf(event.currentTarget);

    this.tabTargets.forEach(element => {
      element.classList.remove(this.selectedTabClass);
    });

    this.contentTargets.forEach(element => {
      element.classList.remove(this.selectedContentClass);
    });

    this.tabTargets[index].classList.add(this.selectedTabClass);
    this.contentTargets[index].classList.add(this.selectedContentClass);
  }
}
